import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Modal,
  Radio,
  Row,
  notification,
} from 'antd'
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { PAGE_HEAD_TITLE } from '../../constants'
import { Database } from '../../firebase'
import { Header } from '../../styles/styles'
import UserContext from '../../userContext'
import { getUserAccountPlan, humanReadableNumber } from '../../utils/Helpers'
import { locationOptions } from '../locationDropdown'
import UserPaymentModal from './PaymentModal'

const PlansComponent = (props) => {
  const authUser = useContext(UserContext)
  const userPlanCheckIfExpired = getUserAccountPlan(authUser.accountData)

  // console.log("userPlanCheckIfExpired:", userPlanCheckIfExpired);

  const [plans, setPlans] = useState([])
  const [planMonthlyYearly, setPlanMonthlyYearly] = useState('MONTHLY')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [planSelected, setPlanSelected] = useState(null)
  const [locationData, setLocationData] = useState(locationOptions[6])
  const [currencySelected, setCurrencySelected] = useState(
    locationOptions[6].currencyCode,
  )
  // const [paymentData, setPaymentData] = useState({});

  const planDurationOptions = [
    { label: 'Monthly', value: 'MONTHLY' },
    { label: 'Yearly', value: 'YEARLY' },
  ]

  useEffect(() => {
    document.title = `Plans ${PAGE_HEAD_TITLE}`
    ;(async function () {
      await fetchPlans()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchPlans = async () => {
    const q = query(collection(Database, 'subscriptionPlans'))
    const querySnapshot = await getDocs(q)

    setPlans(
      querySnapshot.docs.map((doc) => {
        const data = doc.data()
        return {
          ...data,
        }
      }),
    )
  }

  // eslint-disable-next-line
  const createPlans = async () => {
    await setDoc(doc(Database, 'subscriptionPlans', 'FREE'), {
      planId: 'FREE',
      monthly: {
        price: 0,
        priceAUD: 0,
        priceCAD: 0,
        priceEUR: 0,
        priceINR: 0,
        priceSGD: 0,
        priceAED: 0,
        priceGBP: 0,
        priceUSD: 0,
        validityDays: 0,
        quotaAiRequest: 1000,
        quotaKwResearch: 120,
        quotaGoogleKwResearch: 120,
        quotaBingKwResearch: 120,
        quotaSpyAdsKw: 1000,
      },
      yearly: {
        price: 0,
        priceAUD: 0,
        priceCAD: 0,
        priceEUR: 0,
        priceINR: 0,
        priceSGD: 0,
        priceAED: 0,
        priceGBP: 0,
        priceUSD: 0,
        validityDays: 0,
        quotaAiRequest: 1000,
        quotaKwResearch: 120,
        quotaGoogleKwResearch: 120,
        quotaBingKwResearch: 120,
        quotaSpyAdsKw: 1000,
      },
    })

    // await setDoc(doc(Database, "subscriptionPlans", "TEST_PLAN"), {
    //     planId: "TEST_PLAN",
    //     backgroundColor: '',
    //     monthly: {
    //         price: 1,
    //         priceAUD: 0,
    //         priceCAD: 0,
    //         priceEUR: 0,
    //         priceINR: 0,
    //         priceSGD: 0,
    //         priceAED: 0,
    //         priceGBP: 0,
    //         priceUSD: 0,
    //         validityDays: 30,
    //         quotaAiRequest: 130000,
    //         quotaGoogleKwResearch: 1000,
    //         quotaYoutubeKwResearch: 1000,
    //         quotaSpyAdsKw: 1000,
    //     },
    //     yearly: {
    //         price: 1,
    //         priceAUD: 0,
    //         priceCAD: 0,
    //         priceEUR: 0,
    //         priceINR: 0,
    //         priceSGD: 0,
    //         priceAED: 0,
    //         priceGBP: 0,
    //         priceUSD: 0,
    //         validityDays: 365,
    //         quotaAiRequest: 130000,
    //         quotaGoogleKwResearch: 1000,
    //         quotaYoutubeKwResearch: 1000,
    //         quotaSpyAdsKw: 1000,
    //     }
    // });

    await setDoc(doc(Database, 'subscriptionPlans', 'BRONZE'), {
      planId: 'BRONZE',
      backgroundColor: '',
      monthly: {
        price: 39,
        priceUSD: 39,
        priceAUD: 55,
        priceCAD: 49,
        priceEUR: 35,
        priceINR: 2901,
        priceSGD: 53,
        priceAED: 144,
        priceGBP: 29,
        validityDays: 30,
        quotaAiRequest: 20000,
        quotaKwResearch: 2400,
        quotaGoogleKwResearch: 2400,
        quotaBingKwResearch: 2400,
        quotaSpyAdsKw: 20000,
      },
      yearly: {
        price: 399,
        priceUSD: 399,
        priceAUD: 555,
        priceCAD: 500,
        priceEUR: 350,
        priceINR: 29673,
        priceSGD: 538,
        priceAED: 1466,
        priceGBP: 293,
        validityDays: 365,
        quotaAiRequest: 200000,
        quotaKwResearch: 24000,
        quotaGoogleKwResearch: 24000,
        quotaBingKwResearch: 24000,
        quotaSpyAdsKw: 200000,
      },
    })

    await setDoc(doc(Database, 'subscriptionPlans', 'SILVER'), {
      planId: 'SILVER',
      monthly: {
        price: 69,
        priceUSD: 69,
        priceAUD: 96,
        priceCAD: 87,
        priceEUR: 61,
        priceINR: 5132,
        priceSGD: 93,
        priceAED: 254,
        priceGBP: 51,
        validityDays: 30,
        quotaAiRequest: 40000,
        quotaKwResearch: 4800,
        quotaGoogleKwResearch: 4800,
        quotaBingKwResearch: 4800,
        quotaSpyAdsKw: 40000,
      },
      yearly: {
        price: 699,
        priceUSD: 699,
        priceAUD: 973,
        priceCAD: 876,
        priceEUR: 614,
        priceINR: 51983,
        priceSGD: 943,
        priceAED: 2566,
        priceGBP: 513,
        validityDays: 365,
        quotaAiRequest: 400000,
        quotaKwResearch: 48000,
        quotaGoogleKwResearch: 48000,
        quotaBingKwResearch: 48000,
        quotaSpyAdsKw: 400000,
      },
    })

    await setDoc(doc(Database, 'subscriptionPlans', 'GOLD'), {
      planId: 'GOLD',
      monthly: {
        price: 99,
        priceUSD: 99,
        priceAUD: 138,
        priceCAD: 124,
        priceEUR: 87,
        priceINR: 7363,
        priceSGD: 134,
        priceAED: 364,
        priceGBP: 73,
        validityDays: 30,
        quotaAiRequest: 80000,
        quotaKwResearch: 9600,
        quotaGoogleKwResearch: 9600,
        quotaBingKwResearch: 9600,
        quotaSpyAdsKw: 80000,
      },
      yearly: {
        price: 999,
        priceUSD: 999,
        priceAUD: 1390,
        priceCAD: 1251,
        priceEUR: 877,
        priceINR: 74292,
        priceSGD: 1347,
        priceAED: 3670,
        priceGBP: 733,
        validityDays: 365,
        quotaAiRequest: 800000,
        quotaKwResearch: 96000,
        quotaGoogleKwResearch: 96000,
        quotaBingKwResearch: 96000,
        quotaSpyAdsKw: 800000,
      },
    })
  }

  const handlePeriodChange = (e) => {
    setPlanMonthlyYearly(e.target.value)
  }

  // const showModal = () => {
  //     setIsModalVisible(true);
  // };

  const extraButton = (plan) => {
    if (authUser.accountType !== 'ADMIN') {
      if (plan.planId === 'FREE') {
        return null
      } else if (
        plan.planId === authUser.accountData.subscriptionPlan.planId &&
        userPlanCheckIfExpired === 'EXPIRED' &&
        authUser.accountData.subscriptionPlan.planMonthlyYearly ===
          planMonthlyYearly
      ) {
        return (
          <span
            style={{ color: '#ff9800', cursor: 'pointer', fontWeight: '600' }}
            onClick={() => {
              // setIsModalVisible(true);
              showConfirm(plan)
            }}
          >
            Renew
          </span>
        )
      } else if (
        plan.planId === authUser.accountType &&
        authUser.accountData.subscriptionPlan.planMonthlyYearly ===
          planMonthlyYearly
      ) {
        return (
          <Badge
            status="processing"
            text="Active"
            style={{ fontWeight: '600' }}
          />
        )
      } else {
        return (
          <span
            style={{ color: '#175BCB', cursor: 'pointer' }}
            onClick={() => {
              // setIsModalVisible(true);
              showConfirm(plan)
            }}
          >
            Subscribe
          </span>
        )
      }
    } else {
      return null
    }
  }

  const showConfirm = (plan) => {
    // console.log("plan:", plan)
    let item = planMonthlyYearly === 'MONTHLY' ? plan.monthly : plan.yearly
    let currencyPrice = item[`price${currencySelected}`]
    const countrySelected = locationOptions.find(
      (item) => item.currencyCode === currencySelected,
    )
    if (currencyPrice) {
      currencyPrice = `${currencyPrice}${countrySelected.currencySymbol}`
    }

    Modal.confirm({
      className: 'extra-pad-modal',
      title: 'Do you Want to purchase this plan?',
      icon: <ExclamationCircleOutlined />,
      content: `Plan cost : ${currencyPrice}`,
      onOk: () => {
        // console.log("plan:", plan)
        setPlanSelected(plan)
        setIsModalVisible(true)
      },
      onCancel: () => {
        notification['error']({ message: 'You have cancelled purchase!' })
      },
    })
  }

  const itemOrder = ['FREE', 'TEST_PLAN', 'BRONZE', 'SILVER', 'GOLD']
  plans.sort(
    (a, b) => itemOrder.indexOf(a.planId) - itemOrder.indexOf(b.planId),
  )

  return (
    <div className="content-wrapper">
      <div className="collection-header">
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <Header>Subscription Plans</Header>
          <Dropdown
            overlay={
              <Menu>
                {locationOptions.map((item, key) => (
                  <Menu.Item
                    key={key}
                    onClick={() => {
                      setLocationData(item)
                      setCurrencySelected(item.currencyCode)
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {item.text}
                      <div>
                        <img
                          src={`/country/${item.countryCode.toLowerCase()}.png`}
                          alt={item.countryCode}
                          style={{ marginRight: '0' }}
                        />
                      </div>
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomLeft"
            arrow
            className="currency-dropdown currency-dropdown-2"
          >
            <Button>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {locationData.text}
                <div style={{}}>
                  <img
                    src={`/country/${locationData.countryCode.toLowerCase()}.png`}
                    alt={locationData.countryCode}
                    style={{ marginRight: '5px' }}
                  />
                  <DownOutlined style={{ float: 'right', marginTop: '6px' }} />
                </div>
              </div>
            </Button>
          </Dropdown>
        </div>

        <div style={{ marginTop: '10px' }}>
          <Radio.Group
            options={planDurationOptions}
            onChange={handlePeriodChange}
            value={planMonthlyYearly}
            optionType="button"
            buttonStyle="solid"
            size="small"
          />
        </div>
      </div>

      {/* <Button className="primary-button" onClick={showModal}>
            Test Payment
        </Button> */}

      {/* <Button className="primary-button" htmlType="submit" onClick={() => createPlans()}>Create/Update Plans in FIREBASE</Button> */}

      <Row gutter={16}>
        {plans &&
          plans.map((plan, index) => {
            let item =
              planMonthlyYearly === 'MONTHLY' ? plan.monthly : plan.yearly

            let currencyPrice = item[`price${currencySelected}`]
            const countrySelected = locationOptions.find(
              (item) => item.currencyCode === currencySelected,
            )
            if (currencyPrice) {
              currencyPrice = `${currencyPrice}${countrySelected.currencySymbol}`
            } else {
              currencyPrice = 'FREE'
            }

            return (
              <Col
                span={6}
                key={`plan-idx-${index}`}
                style={{ marginBottom: '20px' }}
              >
                <Card
                  title={plan.planId}
                  bordered={false}
                  extra={extraButton(plan)}
                  style={{
                    backgroundColor: plan.backgroundColor
                      ? plan.backgroundColor
                      : '#FFFFFF',
                  }}
                >
                  <div className="span-color">
                    <p>
                      <b>Price:</b>{' '}
                      <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        {currencyPrice}
                      </span>
                    </p>
                    <p>
                      <b>Validity (days):</b>{' '}
                      <span>
                        {item.validityDays ? item.validityDays : 'UNLIMITED'}
                      </span>
                    </p>
                    <br />
                    <p>
                      <b>Facebook Hidden Interest:</b>{' '}
                      <span>
                        {plan.planId === 'FREE' ? 'View Only' : 'View & Save'}
                      </span>
                    </p>
                    <br />
                    <p>
                      <b>AI Request Quota:</b>{' '}
                      <span>
                        {humanReadableNumber(item.quotaAiRequest, true)}
                      </span>{' '}
                      chars
                    </p>
                    <p>
                      <b>Keyword Research Quota:</b>{' '}
                      <span>
                        {humanReadableNumber(item.quotaKwResearch, true)}
                      </span>{' '}
                      creds
                    </p>
                    <p>
                      <b>Google Ads Planner Quota:</b>{' '}
                      <span>
                        {humanReadableNumber(item.quotaGoogleKwResearch, true)}
                      </span>{' '}
                      creds
                    </p>
                    <p>
                      <b>Bing Ads Planner Quota:</b>{' '}
                      <span>
                        {humanReadableNumber(item.quotaBingKwResearch, true)}
                      </span>{' '}
                      creds
                    </p>
                    <p>
                      <b>Spy Ads Research Quota:</b>{' '}
                      <span>
                        {humanReadableNumber(item.quotaSpyAdsKw, true)}
                      </span>{' '}
                      creds
                    </p>
                    <p>
                      <b>Live workshops:</b>{' '}
                      {/* <span>{plan.planId === 'FREE' ? 'N/A' : 'Yes'}</span> */}
                      <span>Yes</span>
                    </p>
                  </div>
                </Card>
              </Col>
            )
          })}
      </Row>

      <UserPaymentModal
        authUser={authUser}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        planMonthlyYearly={planMonthlyYearly}
        currencySelected={currencySelected}
        planSelected={planSelected}
      />
    </div>
  )
}

export default PlansComponent
