import React, { useEffect, useRef, useState } from "react";
import "./FacebookGradel.css";
import FacebookIcon from "./facebookIcon.svg";
import { Header } from "../../styles/styles";
import FacebookB2b from "./FacebookB2b";
import FacebookEcom from "./FacebookEcom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
const Facebookgradel = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const option = queryParams.get("option");

  const history = useHistory();
  const handleClickOption = (value) => {
    history.push(`/leads-facebook-grader?option=${value}`);
  };
  return (
    <>
      {option === null && (
        <div className="meta-card-top-container">
          <Header className="meta-card-header-name">Choose Your Goal</Header>
          <div className="meta-card-center">
            <div onClick={() => handleClickOption("ecommerce")}>
              <MetaAdsCard title="E-commerce Brand | Ultimate Meta Ads Performance" />
            </div>
            <div onClick={() => handleClickOption("b2b")}>
              <MetaAdsCard title="B2B Brand | Ultimate Meta Ads Performance" />
            </div>
          </div>
        </div>
      )}
      {option === "b2b" && <FacebookB2b />}
      {option === "ecommerce" && <FacebookEcom />}
    </>
  );
};

export default Facebookgradel;
const MetaAdsCard = ({ title }) => {
  return (
    <div className="meta-ads-card">
      <div className="card-content">
        <div className="data-box red-box">
          <span className="facebook-icon">
            <img src={FacebookIcon} alt="facebook" />
          </span>
          <p>28.81</p>
        </div>
        <div className="data-box green-box">
          <span className="facebook-icon">
            <img src={FacebookIcon} alt="facebook" />
          </span>
          <p>19.23</p>
        </div>
        <div className="data-box green-box">
          <span className="facebook-icon">
            <img src={FacebookIcon} alt="facebook" />
          </span>
          <p>20.30</p>
        </div>
        <div className="chart-box">
          <span className="facebook-icon">
            <img src={FacebookIcon} alt="facebook" />
          </span>
          {/* Dummy chart for illustration */}
          <svg width="100%" height="60">
            <polyline
              points="0,40 20,20 40,30 60,10 80,40"
              style={{ fill: "none", stroke: "blue", strokeWidth: 2 }}
            />
          </svg>
        </div>
        <div className="table-placeholder">
          <span className="facebook-icon">
            <img src={FacebookIcon} alt="facebook" />
          </span>
          <div className="table-rows">
            <div className="table-row" />
            <div className="table-row" />
          </div>
        </div>
      </div>
      <div className="card-footer">
        <p>{title}</p>
        <span className="facebook-icon">
          <img src={FacebookIcon} alt="facebook" />
        </span>
      </div>
    </div>
  );
};
