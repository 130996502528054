import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import logo from "../../../styles/images/facebooklogo.svg";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import "../components/TableWithTrends.css";
import { calculatePercentageChange } from "../../../utils/Helpers";

const CustomTooltip = ({ children, tooltipText, date }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <span
      style={{ position: "relative", cursor: "pointer" }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "-60",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            whiteSpace: "nowrap",
            fontSize: "12px",
            zIndex: 10,
          }}
        >
          {/* Tooltip Text with border */}
          <div
            style={{
              borderBottom: "1px solid #ddd",
              borderRadius: "4px",
              padding: "4px",
              marginRight: "4px", // Space between the columns
              flex: 1, // Ensures this column takes up available space
            }}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
          />

          {/* Date Field with border */}
          <div
            style={{
              padding: "4px",
              flex: 0, // Keeps the date field size fixed
            }}
          >
            Compare To : {date}
          </div>
        </div>
      )}
    </span>
  );
};

const TableWithTrends = ({ prvdata, data, date }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const combinedData = data.flatMap((item, index) => {
    const normalDate = new Date(item?.date);

    const prevIndex = index; // This assumes the data is ordered correctly and matches the number of entries.

    // Ensure the index is valid for prvdata
    const previousDataItem =
      prevIndex < prvdata.length ? prvdata[prevIndex] : null;

    return item.data.length > 0
      ? item.data.map((entry, entryIndex) => {
          const entryDateStart = new Date(entry.date_start);
          const previousEntry = previousDataItem?.data[entryIndex];
          // Previous values for comparison
          const previousReach = previousEntry
            ? parseFloat(previousEntry.reach)
            : 0;
          const previousSpend = previousEntry
            ? parseFloat(previousEntry.spend)
            : 0;
            const previousLeads = previousEntry?.actions?.some(
              (action) => action.action_type === "lead"
            )
              ? parseFloat(
                  previousEntry.actions.find((action) => action.action_type === "lead")?.value
                )
              : 0;
             
          const previousUniqueClicks = previousEntry
            ? parseFloat(previousEntry.cost_per_unique_click)
            : 0;
          const CostPrevLeads =
            previousLeads > 0 ? (previousSpend / previousLeads).toFixed(2) : 0;

          // Current values
          const currentReach = entry.reach ? parseFloat(entry.reach) : 0;
          const currentSpend = entry.spend ? parseFloat(entry.spend) : 0;
          const currentLeads = entry.actions?.some(
            (action) => action.action_type === "lead"
          )
            ? parseFloat(
                entry.actions.find((action) => action.action_type === "lead")
                  ?.value
              )
            : 0;
         
          const currentUniqueClicks = entry?.cost_per_unique_click || 0;
          const CostLeads =
            currentLeads > 0 ? (currentSpend / currentLeads).toFixed(2) : 0;
     
          return {
            ...entry,

            date_start: formatDate(normalDate), // Format the date range using itemDateStart
            previousamountSpent: previousSpend,
            previousreach: previousReach,
            previousuniqueClick: previousUniqueClicks?.toFixed(2),
            previousleads: previousLeads,
            previouscostperleads: CostPrevLeads,
            previousDate: formatDate(previousDataItem?.date),
            amountSpent: entry?.spend,
            leads: currentLeads > 0 ? currentLeads : 0,
            costperleads:
              currentLeads > 0 ? (currentSpend / currentLeads).toFixed(2) : 0,
            uniqueClick:
              currentUniqueClicks > 0
                ? (currentSpend / currentUniqueClicks).toFixed(2)
                : 0,
            // Add dynamic comparison fields
            reachChange: calculatePercentageChange(currentReach, previousReach),
            amountSpentChange: calculatePercentageChange(
              currentSpend,
              previousSpend
            ),
            leadsChange: calculatePercentageChange(currentLeads, previousLeads),
            uniqueClickChange: calculatePercentageChange(
              currentUniqueClicks,
              previousUniqueClicks
            ),
            costperleadsChange: calculatePercentageChange(
              CostLeads,
              CostPrevLeads
            ),
          };
        })
      : [
          {
            date_start: formatDate(normalDate),
            previousamountSpent: 0,
            previousreach: 0,
            previousuniqueClick: 0,
            previousleads: 0,
            previouscostperleads: 0,
            previousDate: 0,
            amountSpent: 0,
            reach: 0,
            leads: 0,
            costperleads: 0,
            uniqueClick: 0,
            reachChange: 0,
            amountSpentChange: 0,
            leadsChange: 0,
            uniqueClickChange: 0,
            costperleadsChange: 0,
          },
        ];
  });


  const createDynamicFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;
    const tooltipText = `${label}: ₹ <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        ₹{value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const ValueFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;
    const tooltipText = `${label}: <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        {value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const columns = [
    {
      dataField: "date_start",
      text: "Date",
      sort: false,
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "spend",
      text: "Amount Spent",
      sort: false,
      formatter: createDynamicFormatter("Amount Spent", "amountSpent"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "reach",
      text: "Reach",
      sort: false,
      formatter: ValueFormatter("Reach", "reach"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "uniqueclick",
      text: "Unique Click",
      sort: false,
      formatter: ValueFormatter("Unique Click", "uniqueClick"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "leads",
      text: "Leads (All)",
      sort: false,
      formatter: ValueFormatter("Leads (All)", "leads"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "costperleads",
      text: "Cost Per Leads (All)",
      sort: false,
      formatter: createDynamicFormatter("Cost Per Leads (All)", "costperleads"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
  ];

  return (
    <div className="table-card">
      <h5 className="card-title">
        <img style={{ width: "24px", height: "24px" }} src={logo} alt="fb" />{" "}
        Funnel Analysis from Meta Amount Spent to Converted Lead for | {date}
      </h5>
      <p className="text-muted">
        Amount Spent, Reach, Unique Clicks, Leads (All), Cost Per Lead (All)
      </p>
      <div className="table-scroll-wrapper">
        <BootstrapTable
          keyField="date"
          data={combinedData}
          columns={columns}
          bordered={false}
          hover
          condensed
          rowStyle={{ border: "none !important" }}
          classes="custom-table"
          noDataIndication={() => <div>No data available</div>}
        />
      </div>
    </div>
  );
};

export default TableWithTrends;
